var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Import Document CSV to BigQuery"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"importForm",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"CSV File","label-for":"v-csv-file"}},[_c('validation-provider',{attrs:{"name":"File","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"inputFile",attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Choose a file or dropr it here...","drop-placeholder":"Drop file here...","accept":".csv","required":""},model:{value:(_vm.csvFile),callback:function ($$v) {_vm.csvFile=$$v},expression:"csvFile"}}),_c('div',{staticClass:"mt-0"},[_vm._v(" Selected file: "+_vm._s(_vm.csvFile ? _vm.csvFile.name : '')+" ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Cloumn Name","rules":"required|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Template","label-for":"v-template","required":""}},[_c('b-form-select',{attrs:{"html-field":"label","value-field":"_id","label-field":'label',"options":_vm.templatesOptions,"state":errors.length > 0 ? false:null},on:{"change":_vm.initTemplate},model:{value:(_vm.templateId),callback:function ($$v) {_vm.templateId=$$v},expression:"templateId"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Big Query DataSet","label-for":"v-bigQueryDataSet"}},[_c('validation-provider',{attrs:{"name":"DataSet Name","rules":"required|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-bigQueryDataSet","placeholder":"Big Query DataSet","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.bqDataSet),callback:function ($$v) {_vm.bqDataSet=$$v},expression:"bqDataSet"}}),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Big Query Table","label-for":"v-bigQueryTable"}},[_c('validation-provider',{attrs:{"name":"Table Name","rules":"required|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-bigQueryTable","placeholder":"Big Query Table","required":"","state":errors.length > 0 ? false:null},model:{value:(_vm.bqTable),callback:function ($$v) {_vm.bqTable=$$v},expression:"bqTable"}}),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.loading},on:{"click":_vm.importDocument}},[_vm._v(" Import ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary","disabled":_vm.loading}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1),_c('b-card-code',{attrs:{"no-body":"","title":"Imported Documents"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.documents,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.id),expression:"data.item.id",modifiers:{"hover":true,"top":true}}]},[_vm._v(" # ")])]}},{key:"cell(createdAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(data.item.createdAt))+" ")]}}])}),(_vm.rows > 25)?_c('div',{staticClass:"mt-1 d-flex justify-content-center"},[_c('b-pagination',{attrs:{"hide-goto-end-buttons":"","total-rows":_vm.rows,"per-page":_vm.perPage},on:{"change":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }