<template>

  <div>

    <b-card
      title="Import Document CSV to BigQuery"
    >

      <validation-observer ref="simpleRules">

        <b-form
          ref="importForm"
          @submit.prevent
        >
          <b-row>

            <b-col cols="12">
              <b-form-group
                label="CSV File"
                label-for="v-csv-file"
              >

                <validation-provider
                  #default="{ errors }"
                  name="File"
                  rules="required"
                >
                  <b-form-file
                    ref="inputFile"
                    v-model="csvFile"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Choose a file or dropr it here..."
                    drop-placeholder="Drop file here..."
                    accept=".csv"
                    required
                  />
                  <div class="mt-0">
                    Selected file: {{ csvFile ? csvFile.name : '' }}
                  </div>
                  <span class="text-danger"> {{ errors[0] }} </span>
                </validation-provider>
              </b-form-group>

            </b-col>

            <b-col cols="12">

              <validation-provider
                #default="{ errors }"
                name="Cloumn Name"
                rules="required|alpha_dash"
              >

                <b-form-group
                  label="Template"
                  label-for="v-template"
                  required
                >
                  <b-form-select
                    v-model="templateId"
                    html-field="label"
                    value-field="_id"
                    :label-field="'label'"
                    :options="templatesOptions"
                    :state="errors.length > 0 ? false:null"
                    @change="initTemplate"
                  />

                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">

              <b-form-group
                label="Big Query DataSet"
                label-for="v-bigQueryDataSet"
              >
                <validation-provider
                  #default="{ errors }"
                  name="DataSet Name"
                  rules="required|alpha_dash"
                >
                  <b-form-input
                    id="v-bigQueryDataSet"
                    v-model="bqDataSet"
                    placeholder="Big Query DataSet"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                  <span class="text-danger"> {{ errors[0] }} </span>

                </validation-provider>

              </b-form-group>
            </b-col>

            <b-col cols="6">

              <b-form-group
                label="Big Query Table"
                label-for="v-bigQueryTable"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Table Name"
                  rules="required|alpha_dash"
                >
                  <b-form-input
                    id="v-bigQueryTable"
                    v-model="bqTable"
                    placeholder="Big Query Table"
                    required
                    :state="errors.length > 0 ? false:null"
                  />

                  <span class="text-danger"> {{ errors[0] }} </span>

                </validation-provider>

              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="loading"
                @click="importDocument"
              >
                Import
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                :disabled="loading"
              >
                Reset
              </b-button>

            </b-col>
          </b-row>
        </b-form>

      </validation-observer>

    </b-card>
    <b-card-code
      no-body
      title="Imported Documents"
    >

      <b-overlay
        :show="loading"
        rounded="sm"
      >

        <b-table
          striped
          hover
          :items="documents"
          :fields="fields"
        >

          <template #cell(id)="data">
            <span v-b-tooltip.hover.top="data.item.id">
              #
            </span>
          </template>

          <template #cell(createdAt)="data">
            {{ data.item.createdAt|formatDateTime }}
          </template>

          <!-- <template #cell(actions)="data">
                  <router-link :to="{ name: 'edit-documents', params: { documentsId: data.item.id }}">
                    <b-button

                      variant="primary"
                      size="sm"
                      class="btn-tour-finish mr-1"
                    >
                    Edit
                    </b-button>

                  </router-link>

                  <a class="text-danger"  @click="remove(data.item.id)" >Remove</a>
              </template> -->

        </b-table>

        <div
          v-if="rows > 25"
          class="mt-1 d-flex justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            hide-goto-end-buttons
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
          />
        </div>

      </b-overlay>

    </b-card-code>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BFormSelect, BFormInput, BFormFile, BTable, BButton, VBTooltip, BPagination, BSpinner, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

import vSelect from 'vue-select'
import {
  required,
  alpha_num,
  alpha_dash,
} from 'vee-validate/dist/rules'

extend('required', required)
extend('alpha_num', alpha_num)
extend('alpha_dash', alpha_dash)

export default {
  components: {
    BCardCode,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BTable,
    BButton,
    BPagination,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormSelect,
    BOverlay,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      newDocument: {},
      bqTable: null,
      bqDataSet: null,
      csvFile: null,
      templateId: null,
      loading: false,
      templatesOptions: [
      ],
      documents: [],
      fields: ['id',
        {
          key: 'fileName',
          label: 'file',
          class: 'max_with_300',
        },
        'totalInserts',
        {
          key: 'bqTable',
          label: 'Bq Table',
          class: 'max_with_200',
        },
        'bqDataSet',
        'createdAt'],
      currentPage: 1,
      perPage: 25,
      lastPage: 0,
      submited: false,
      rows: 0,
    }
  },
  computed: {
    bqtable: () => {
      if (this.newDocument) {
        return this.newDocument
      }
    },
  },

  created() {
    this.loadTemlates()
    this.loadDocuments()
  },

  methods: {

    handlePageChange(value) {
      this.page = value

      this.loadDocuments(this.page)
    },

    loadTemlates() {
      this.loading = true
      this.$store.dispatch('documentTemplate/fetchAll')
        .then(result => {
          this.templatesOptions = [{ label: ' ', _id: '' }, ...result.data.docs]
          this.loading = false
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
          this.loading = false
        }).finally(() => {
          this.loading = false
        })
    },

    loadDocuments(page = 1) {
      this.loading = true

      this.$store.dispatch('document/fetchAll', page)
        .then(result => {
          this.documents = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
          this.loading = false
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
          this.loading = false
        }).finally(() => {
          this.loading = false
        })
    },
    initTemplate() {
      if (this.templateId) {
        const selectedTemplate = this.templatesOptions.find(template => template._id == this.templateId)
        // @TODO: Use object
        // this.newDocument.bqDataSet =  selectedTemplate.defaultBqDataSet
        // this.newDocument.bqTable = selectedTemplate.defaultBqTable
        this.bqTable = selectedTemplate.defaultBqTable
        this.bqDataSet = selectedTemplate.defaultBqDataSet
      } else {
        this.bqTable = null
        this.bqDataSet = null
      }
    },
    importDocument() {
      this.$refs.simpleRules.validate().then(valid => {
        if (valid) {
          const formData = new FormData()
          formData.append('documentTemplateId', this.templateId)
          formData.append('bqTable', this.bqTable)
          formData.append('bqDataSet', this.bqDataSet)
          formData.append('file', this.csvFile)

          this.loading = true
          this.$store.dispatch('document/create', formData)
            .then(result => {
              this.$refs.simpleRules.reset()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  text: `${result.data.total} items inserted successfully`,
                  variant: 'success',
                },
              })

              // this.$refs.importForm.reset();
              this.$refs.inputFile.reset()

              this.loadDocuments(1)

              this.loading = false
            },
            error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: error.response?.data?.message || 'Error while importing file',
                  variant: 'danger',
                  timeout: 5000,
                },
              })

              this.loading = false
            })
        } else {
          this.showToast('Form', 'Check your inputs please', 'warning')
        }
      }, error => {
        console.log(error)
      })
    },

    remove(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {
        if (result.value === true) {
          this.loading = true

          this.$store.dispatch('document/remove', id).then(
            () => {
              this.loadDocuments()
            },
            error => {
              console.log(err)
            },
          ).finally(() => {
            this.loading = false
          })
        }
      },
      error => {
        console.log(err)
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
